<template>
  <div :style="getElementStyle" v-if="data.properties.filed_content !== 'Hide'" style="display: flex;">
    <template v-if="data.group && data.group.key">
      <el-radio
      :label="data.label"
        v-model="form[data.group.key]"
        :style="getStyle"
        :disabled="isFromDocument ? false : isReadOnly() || checkReadonly() || readonly()"
        @change="applyFormRules"
      >
        {{ label||data.label }}
        <span v-if="data.validations.required && hasLabel && !isView" style="color: red; font-weight: bold">*</span>
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <span slot="reference">
            <i class="el-icon-info"></i>
          </span>
        </el-popover>
      </el-radio>
    </template>
    <template v-else>
      <el-radio
      :label="data.label"
        v-model="form[data.key]"
        :style="getStyle"
        :disabled="isFromDocument ? false : isReadOnly() || checkReadonly() || readonly()"
        @change="applyFormRules"
        style="font-weight: bold;"
        :class="{ 'highlighted-label': highlight }"
      >
        {{ label||data.label}}
        <span v-if="data.validations.required && hasLabel && !isView" style="color: red; font-weight: bold; margin-right: 5px;">*</span>
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <span slot="reference">
            <i class="el-icon-info"></i>
          </span>
        </el-popover>
      </el-radio>
    </template>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
export default {
  name: "templates-formComponentsExecute-RadioExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "fieldsData",
    "checkIsDisabled",
    "isFromDocument",
    "colorFields",
    "highlight"
    
  ],
  computed: {
    ...mapGetters("auth", ["getAutheniticatedUser"]),
    getStyle() {
      return this.hasLabel
        ? `height:${this.data.height - 5}px;width:${this.data.width - 5}px;`
        : "";
    },
  },
  mounted() {
    //if (this.data.default_value) {
    //  this.form[this.data.key] = this.data.default_value;
    //}
    if (!this.form[this.data.key]) {
      this.$set(this.form, this.data.key,);
    }
    if(this.form[this.data.key] !== ''){
      this.applyFormRules()
    }
  },
  data() {
    return {
      validations: [],
      group: {
        label: "",
        key: "",
        max_value: 0,
        min_value: 0,
      },
    };
  },
  methods: {
    // checkReadonly(){
    //     // method from mixin

    //     return !(this.fieldFilledByCurrentUser(this.data.filled_by)) || this.isView;
    //   },
    isReadOnly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
      
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    changeField() {
      if (this.data.group && this.data.group.key) {
        this.$set(this.form, this.data.group.key, this.data.key);
      } else {
        this.$set(this.form, this.data.key, this.data.key);
      }
      this.error = false;
    },
  },
};
</script>

<style lang="scss">
</style>